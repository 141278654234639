<template>
    <router-view v-slot="{ Component }">
        <KeepAlive v-if="routerAlive">
            <component :is="Component" :key="route.path"  />
        </KeepAlive>
        <component :is="Component" :key="route.path" v-else />
    </router-view>
</template>
<script setup lang="ts">
// 外部依赖
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

// 定义router
const router = useRouter();
// 定义当前路由
const route = useRoute();

// 获取当前路由是否缓存
const routerAlive = computed(() => {
  const { meta } = router.currentRoute.value;
  return !!(meta.hasOwnProperty('alive') && meta.alive);
});
</script>
