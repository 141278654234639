export default {
  // common label
  'label.type': '类型',
  'label.name': '名称',
  'label.content': '内容',
  'label.remark': '备注',
  'label.createTime': '创建时间',
  'label.operation': '操作',
  'label.code': '编码',
  'label.date': '日期',
  'label.title': '标题',
  'label.status': '状态',
  'label.department': '部门',
  'label.endpoint': '终端类型',
  'label.username': '用户名',
  'label.unitName': '部门名称',
  'label.UID': 'UID',
  'label.email': '邮箱',
  'label.mobile': '手机号',
  'label.yes': '是',
  'label.no': '否',
  'label.logout': "退出",
  'label.import': '导入',
  'label.export': '导出',
  'label.delete': '删除',
  'label.InvoiceNO': '发票号码',
  'label.CDF':'报关单',
  'label.Expiredate':'到期日期',
  'label.CDD':'申报日期',
  'label.ReturnCDF'	:'复进口报关单号',
  'label.ReturnCDD'	:'复进口申报日期',
  'label.Alertdate':'预警日期',
  'label.CarModel':'车辆型号',
  'label.WaybillNO':'提运单号',
  'label.Type/LOT':'批次',
  'label.ContainerNO':'集装箱号',
  'label.CDFNO':'报关单号',
  'label.ReturnWaybillNO':'复进口提运单号',
  'label.ReturnContainerNO':'复进口集装箱号',
  'label.ReturnCDFNO':'复进口报关单号',
  'label.Return':'复进口-批次',
  'label.PartNO':'部件号码',
  'label.Notes':'备注',
  "label.ExportReviewResult":'出口复核结果',
  "label.ReturnImportReviewResult":'复进口复核结果',
  "label.ExportResult":'出口复核',
  "label.ReturnResult":'进口复核',
  "label.DescriptionEN":"描述（英文）",
  "label.DescriptionCN":'描述（中文）',
  "label.DataStatus":"数据状态",
  "label.singinid":"SIGNI ID",
  "label.SerialNumber":'序号',
  "label.DownloadTemplate":'导入模板下载',
  "label.UpdateBatch":'更新批次',
  "label.MarkasCompleted":'标记完成',
  "label.Delete":'删除',
  'label.permission': '按钮权限',
  // common button
  'message.selectWarning': '请至少选择一条数据！',
  'button.search': '查询',
  'button.reset': '重置',
  'button.cancel': '取消',
  'button.save': '保存',
  'button.add': '添加',
  'button.import': '导入',
  'button.export': '导出',
  'button.ok': '确定',
  'button.synchronize': '同步',
  'button.submit': '提交',
//
'column.title':'修改',
  // common message
  'message.add': '添加成功',
  'message.edit': '编辑成功',
  'message.delete.confirm': '确认要删除该记录吗？',
  'message.delete.success': '删除记录成功。',
  'message.delete.failed': '删除记录失败。',
  'message.rule': '当前信息格式不正确。',
  'message.read.success': '操作成功',
  'message.read.failed': '操作失败',
  'message.async.title': '确认要同步权限吗？',
  'message.async.success': '权限同步成功',
  'message.back': '返回首页',

  // table label
  'label.table.title': '结果包含',
  'label.table.items': '项',
  'label.table.edit': '编辑',
  'label.table.delete': '删除',
  'label.table.detail': '详情',
  'label.table.permission': '权限',
  'label.table.notes': '备注',

  // placeholder
  'placeholder.select': '请选择',
  'placeholder.input': '请输入',
  'placeholder.Startdate': '开始日期',
  'placeholder.Enddate': '结束日期',

  // Audit
  'label.audit.detail': '审计日志详情',

  // Delegation
  'label.delegation.id': '代理人UID',
  'label.delegation.name': '代理人姓名',
  'label.delegation.list': '代理人列表',
  'label.delegation.startDate': '开始日期',
  'label.delegation.endDate': '结束日期',
  'label.delegation.add': '添加代理',
  'label.delegation.edit': '编辑代理',

  'label.delegation.authorizer.id': '授权人工号',
  'label.delegation.authorizer.name': '授权人姓名',

  'rule.delegation.id': '代理人工号不能为空！',
  'rule.delegation.startTime': '开始时间不能为空！',
  'rule.delegation.endTime': '结束时间不能为空！',

  // Dict
  'label.dict.table': '字典表',
  'label.dict.key': '主键',
  'label.dict.cn': '中文',
  'label.dict.en': '英文',
  'label.dict.add': '添加字典',
  'label.dict.edit': '编辑字典',

  'rule.dict.code': '请输入字典编码',
  'rule.dict.name': '请输入字典名称',

  // Notification
  'label.notification.handle': '处理',
  'label.notification.ignore': '已读',
  'label.notification.read': '已读',
  'label.notification.unread': '未读',
  'label.notification.clear': '清空通知',
  'label.notification.more': '查看更多',
  
  // Department
  'label.department.title': '查看部门信息',
  'label.department.unit.name': '部门名称',
  'label.department.unit.id': '部门编号',
  'label.department.short.name': '部门简称',
  'label.department.parent.id': '父级部门',

  // permission
  'label.permission.name': '权限名称',
  'label.permission.code': '权限编码',
  'label.permission.action': '权限动作',
  'label.permission.data': '数据权限',
  'label.permission.title': '权限',
  'label.permission.description': '权限描述',

 // Role
 'label.role.code': '角色编码',
 'label.role.name': '角色名称',
 'label.role.isGlobal': '全局角色',
 'label.role.enabled': '启用',
 'label.role.disabled': '禁用',
 'label.role.description': '角色描述',
 'label.role.add': '新增角色',
 'label.role.edit': '编辑角色',
 'label.role.permission': '编辑权限',

 'rule.role.code': '角色编码不能为空！',
 'rule.role.name': '角色名称不能为空！',
 'rule.role.model': '所在企业不能为空！',

 // user
 'label.user.add': '新增用户',
 'label.user.edit': '修改用户',
 'label.user.roles': '角色列表',

 'rule.user.name': '用户名不能为空！',
 'rule.user.uid': 'uid不能为空！',
 'rule.user.deptCode': '部门名称不能为空！',
 'rule.user.email': 'email不能为空！',
 'rule.user.email.type': 'email格式不正确！',
 'rule.user.mobile': '手机号不能为空！',
 'rule.user.mobile.type': '手机号格式不正确！',

  // container
  'label.menu.search': '查询条件',
  'label.menu.table': '结果展示',
  'label.menu.close': '关闭',
  'label.menu.open': '展开',

  // menu
  'menu.home': '首页',
  'menu.system': 'BCS关务模块',
  'menu.system.rbac': '权限系统',
  'menu.rbac.user': '用户管理',
  'menu.rbac.role': '角色管理',
  'menu.rbac.permission': '权限管理',
  'menu.rbac.department': '部门管理',
  'menu.system.notification': '通知管理',
  'menu.system.dictionary': '数据字典',
  'menu.system.audit': '审计日志',
  'menu.system.delegation': '代理授权',
  'menu.system.notFound': '对不起，你访问的页面不存在',

  //配件报关
  'menu.system.customs':'配件报关',
  'menu.cus.rack':'RACK追踪报表',

  //任务管理
  'menu.system.jobManager':'任务管理',
  'label.jobManager.jobName':'任务名称',
  'label.jobManager.jobGroup':'任务组',
  'label.jobManager.cron':'Cron表达式',
  'label.jobManager.description':'描述',
  'label.jobManager.status':'状态',
  'label.jobManager.misfireType':'misfire策略',
  'label.jobManager.schedulerJobStatus':'任务运行状态',
  'button.jobManager.delete': '删除',
  'button.jobManager.pause': '暂停',
  'button.jobManager.runOnce': '运行一次',
  'button.jobManager.resume': '重启',
  'rule.jobManager.jobName': '任务名称不能为空！',
  'rule.jobManager.jobGroup': '任务组不能为空！',  
  'rule.jobManager.cron': 'Cron表达式不能为空！',  
  'rule.jobManager.misfireType': 'misfire策略不能为空！',  
  'rule.jobManager.status': '状态不能为空！',  
  'rule.jobManager.jobMapKey': 'Key不能为空！',  
  'rule.jobManager.jobMapValue': 'Value不能为空！',  
  'label.jobManager.add': '新增任务',
  'label.jobManager.enabled': '开启',
  'label.jobManager.disabled': '暂停',
  'label.jobManager.jobMaps': '任务参数',
  'label.jobManager.misfireType.MISFIRE_INSTRUCTION_IGNORE_MISFIRE_POLICY': '所有的misfile任务马上执行',
  'label.jobManager.misfireType.MISFIRE_INSTRUCTION_FIRE_ONCE_NOW': '合并部分misfire',
  'label.jobManager.misfireType.MISFIRE_INSTRUCTION_DO_NOTHING': '放弃misfire',
};
