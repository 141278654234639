/*
 * @Description: static routes
 */

//! If unnecessary, please don't set name in root, otherwise it will be shown in breadcrumb.

export default [
  {
    root: {},
    meta: { alive: false },
    path: '/',
    hidden: false,
    redirect: '/home',
    component: 'layout',
    permissions: [],
    children: [
      {
        name: 'home',
        meta: { alive: false, fullScreen: true },
        path: '/home',
        hidden: false,
        component: 'home/index.vue',
        permissions: [],
      },
      {
        name: 'system',
        meta: { alive: false },
        path: '/system',
        hidden: false,
        redirect: '/system/cus/rack',
        component: 'RouterView',
        permissions: [],
        children: [
          {
            name: 'system.customs',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/cus',
            component: 'RouterView',
            redirect:'/system/cus/rack',
            permissions: [],
            children: [
              {
                name: 'cus.rack',
                meta: { alive: false },
                path: '/system/cus/rack',
                hidden: false,
                component: 'system/cus/rack/rack.vue',
                permissions: ['rack.query'],
              }
            ],
          },
          {
            name: 'system.rbac',
            meta: { defaultIcon: 'Seting', activeIcon: 'SetingFilled', alive: false },
            path: '/system/rbac',
            hidden: false,
            redirect: '/system/rbac/user',
            component: 'RouterView',
            permissions: [],
            children: [
              {
                name: 'rbac.user',
                meta: { alive: false },
                path: '/system/rbac/user',
                hidden: false,
                component: 'system/rbac/user/UserList.vue',
                permissions: ['user.query'],
              },
              {
                name: 'rbac.role',
                meta: { alive: false },
                path: '/system/rbac/role',
                hidden: false,
                component: 'system/rbac/role/RoleList.vue',
                permissions: ['role.query'],
              },
              {
                name: 'rbac.permission',
                meta: { alive: false },
                path: '/system/rbac/permission',
                hidden: false,
                component: 'system/rbac/permission/PermissionList.vue',
                permissions: ['permission.query'],
              },
              {
                name: 'rbac.department',
                meta: { alive: false },
                path: '/system/rbac/department',
                hidden: false,
                component: 'system/rbac/department/DepartmentList.vue',
                permissions: ['department.query'],
              },
            ],
          },
          {
            name: 'system.notification',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: false },
            path: '/system/notification',
            hidden: false,
            component: 'system/notification/NotificationList.vue',
            permissions: ['notification.query'],
          },
          {
            name: 'system.dictionary',
            meta: { defaultIcon: 'DataDictionary', activeIcon: 'DataDictionaryFilled', alive: false },
            path: '/system/dict',
            hidden: false,
            component: 'system/dict/DictList.vue',
            permissions: ['dict.query'],
          },
          {
            name: 'system.audit',
            meta: { defaultIcon: 'Audit', activeIcon: 'AuditFilled', alive: false },
            path: '/system/audit',
            hidden: false,
            component: 'system/audit/AuditList.vue',
            permissions: ['audit.query'],
          },
          {
            name: 'system.delegation',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/delegation',
            hidden: false,
            component: 'system/delegation/DelegationList.vue',
            permissions: ['delegation.query'],
          },
          {
            name: 'system.jobManager',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/jobManager',
            hidden: false,
            component: 'system/jobManager/JobManagerList.vue',
            permissions: ['job-Manager.query'],
          },
        ],
      },
      {
        name: 'system.notFound',
        meta: { icon: '' },
        path: '/404',
        hidden: true,
        component: 'exception/404.vue',
        permissions: [],
      },
    ],
  },
];
